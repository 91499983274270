<template>
  <prm-dialog
    v-model:visible="visible"
    :draggable="false"
    modal
    :header="i18n.global.t('component.userSettingsModal.title')"
    :style="{ width: '100%', 'max-width': '700px' }"
    @hide="emit('close')"
  >
    <div class="input-group">
      {{ i18n.global.t('component.userSettingsModal.language') }}
      <prm-dropdown
        v-model="language"
        :options="languageList"
        option-value="id"
        option-label="displayName"
      />
    </div>
    <div class="input-group">
      {{ i18n.global.t('component.userSettingsModal.timeFormat') }}
      <prm-dropdown
        v-model="timeFormat"
        :options="localisationStoreDateFormatList"
        option-value="id"
        option-label="displayName"
      />
    </div>
    <template #footer>
      <prm-button
        type="button"
        outlined
        :disabled="isSubmitting"
        @click="emit('close')"
      >
        {{ i18n.global.t('common.cancel') }}
      </prm-button>
      <prm-button
        type="button"
        :disabled="isSubmitting"
        @click="onSubmit"
      >
        {{ i18n.global.t('common.submit') }}
      </prm-button>
    </template>
  </prm-dialog>
</template>

<script lang="ts" setup>
import PrmDialog from 'primevue/dialog';
import {
  ref,
} from 'vue';
import PrmButton from 'primevue/button';
import {
  i18n,
} from '@i18n';
import {
  useToast,
} from 'primevue/usetoast';
import PrmDropdown from 'primevue/dropdown';
import {
  ELanguage,
  ELocalisationStoreDateFormat,
  localisationStoreDateFormatList,
  languageList,
} from '@/types/localization-store.ts';
import {
  useCustomUserStorageStore,
} from '@/store/pinia/custom-user-storage';

const toast = useToast();
const customUserStorage = useCustomUserStorageStore();

const language = ref<ELanguage>(customUserStorage.customUserSettings!.language);
const timeFormat = ref<ELocalisationStoreDateFormat>(customUserStorage.customUserSettings!.timeFormat);
const isSubmitting = ref(false);

// eslint-disable-next-line func-call-spacing, no-spaced-func
const emit = defineEmits<{
  (e: 'close'): void,
  (e: 'success'): void,
}>();

const visible = ref(true);

function onSubmit() {
  isSubmitting.value = true;
  customUserStorage.createOrUpdateCustomUserSettings({
    language: language.value,
    timeFormat: timeFormat.value,
  }).then(() => {
    emit('success');
    toast.add({
      summary: i18n.global.t('common.success'),
      severity: 'success',
      life: 3000,
    });
  }).finally(() => {
    isSubmitting.value = false;
  });
}
</script>
