import {
  RouteLocationNormalized, RouteLocationRaw,
} from 'vue-router';
import {
  IAuthTokens, authTokenInterceptor,
} from 'axios-jwt';
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import {
  i18n,
} from '@i18n';
import {
  z,
} from 'zod';
import router, {
  ANONYMOUS_ROUTES,
  AUTH_ROUTES,
} from '@/router/index';
import {
  ERouteName,
} from '@/router/route-name';
import {
  TokenService,
  TokenServiceValidationError,
  TokenServiceTokenNotFoundError,
} from '@/services/TokenService';
import config from '@/config/config';
import {
  initOptions,
} from '@/constants/ssoOptions';
import {
  useAppStore,
} from '@/store/pinia/app.ts';
import {
  useCustomUserStorageStore,
} from '@/store/pinia/custom-user-storage.ts';
import zodLocale from '@/constants/zod/export.ts';

export async function authMiddleware(to: RouteLocationNormalized): Promise<RouteLocationRaw | boolean> {
  if (ANONYMOUS_ROUTES.includes(to.name as ERouteName)) {
    return true;
  }

  try {
    /** @deprecated */
    await TokenService.decodedIdToken();
  } catch (error) {
    if (
      !(
        error instanceof TokenServiceValidationError
        || error instanceof TokenServiceTokenNotFoundError
        || (
          error instanceof AxiosError
          && error.response?.status === 401
        )
      )
    ) {
      throw error;
    }

    // there are 2 checks for auth routes
    // here we check if user is UNauthenticated AND is on auth route
    // in this case we do nothing
    // in second check we check if user IS authentocated and is by accident on login route
    // then we redirect to home page
    if (AUTH_ROUTES.includes(to.name as ERouteName)) {
      return true;
    }

    return {
      name: ERouteName.LOGIN,
      query: {
        backUrl: to.fullPath,
      },
    };
  }

  const appStore = useAppStore();
  const customUserStorage = useCustomUserStorageStore();
  await appStore.loadCurrentUser(true);
  await customUserStorage.loadCustomUserSettings();
  i18n.global.locale = customUserStorage.customUserSettings!.language;
  z.setErrorMap(zodLocale[customUserStorage.customUserSettings!.language]);

  // if user accidentally went to auth route manually and is already logged in
  // we redirect to home
  if (AUTH_ROUTES.includes(to.name as ERouteName)) {
    const previousURL = to.query.backUrl as string;
    if (previousURL) {
      return {
        path: previousURL,
      };
    }
    return {
      name: ERouteName.HOME,
    };
  }

  const requiredPermissionList = to.matched.find((_record) => _record.meta.permissionList)?.meta.permissionList;
  const missingPermissions: (string | number)[] = [];

  if (
    requiredPermissionList !== undefined
    && requiredPermissionList.length !== 0
    && !requiredPermissionList.every(
      (_permission) => {
        const app = appStore.user!.appPermissions![_permission.appId];
        if (!app) {
          missingPermissions.push(_permission.appId);
          return false;
        }
        if (!app[_permission.permission]) {
          missingPermissions.push(_permission.permission);
          return false;
        }
        return true;
      },
    )
  ) {
    console.error(`User is missing permission: ${missingPermissions.join(', ')}`);
    return {
      name: ERouteName.HOME,
    };
  }

  return true;
}

export async function requestRefresh(refreshToken: string): Promise<IAuthTokens & {
  idToken: string,
} | string> {
  // Important! Do NOT use the axios instance that you supplied to applyAuthTokenInterceptor (in our case 'axiosInstance')
  // because this will result in an infinite loop when trying to refresh the token.
  // Use the global axios client or a different instance
  const response: AxiosResponse<{
    accessToken: string,
    refreshToken: string,
    idToken: string,
  }> = await axios.post(`${config.VITE_APP_API_URL}/oauth2/refresh`, {
    refreshToken,
    clientId: initOptions.clientId,
  });

  // If your backend supports rotating refresh tokens, you may also choose to return an object containing both tokens:
  return {
    accessToken: response.data.accessToken,
    refreshToken: response.data.refreshToken,
    idToken: response.data.idToken,
  };
}

const interceptor = authTokenInterceptor({
  requestRefresh,
});

export async function handleRequestRefresh(requestConfig: AxiosRequestConfig<any>) {
  return interceptor(requestConfig)
    .catch(async (error) => {
      console.error(error);
      const {
        href,
      } = router.resolve({
        name: ERouteName.LOGIN,
        query: {
          backUrl: router.currentRoute.value.fullPath,
        },
      });
      window.location.href = href;
    });
}
