export default {
  overview: {
    locationDepartment: 'Location/department:',
    certificate: {
      pyrometry: 'AMS2750: Pyrometry',
      furnaceClass: 'Furnace class {value}',
      instrumentationType: 'Instrumentation type {value}',
      heatTreatment: 'AMS2759: Heat Treatment of steel parts',
    },
    capability: {
      gasChip: '{name} - {maxFlow} L/min',
    },
    capacity: {
      minTemp: 'Min temp: {value} °C',
      maxTemp: 'Max temp: {value} °C',
      dimensions: 'Dimensions: {value} mm',
      maxLoad: 'Max load: {value} kg',
    },
  },
};
