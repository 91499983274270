// old implementation of matching 24/12 hours format
export enum ELocalisationStoreDateFormat {
  // 12 hours format
  HOURS_12 = 'en',
  // 24 hours format
  HOURS_24 = 'de',
}

export const localisationStoreDateFormatMap: Record<ELocalisationStoreDateFormat, string> = {
  [ELocalisationStoreDateFormat.HOURS_12]: '12h',
  [ELocalisationStoreDateFormat.HOURS_24]: '24h',
};

export const localisationStoreDateFormatList: {
  id: ELocalisationStoreDateFormat,
  displayName: string,
}[] = Object.entries(localisationStoreDateFormatMap).map(([
  id,
  displayName,
]) => ({
  id: id as ELocalisationStoreDateFormat,
  displayName,
}));

export enum ELanguage {
  EN = 'en',
  DE = 'de',
}

export const languageMap: Record<ELanguage, string> = {
  [ELanguage.EN]: 'en',
  [ELanguage.DE]: 'de',
};

export const languageList: {
  id: ELanguage,
  displayName: string,
}[] = Object.entries(languageMap).map(([
  id,
  displayName,
]) => ({
  id: id as ELanguage,
  displayName,
}));

export function getDefaultLanguage(): ELanguage {
  return navigator.language.startsWith('de')
    ? ELanguage.DE
    : ELanguage.EN;
}

export function getDefaultTimeFormat(): ELocalisationStoreDateFormat {
  return navigator.language.startsWith('de')
    ? ELocalisationStoreDateFormat.HOURS_24
    : ELocalisationStoreDateFormat.HOURS_12;
}
