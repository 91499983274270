export enum ERouteName {
  USER_MANAGEMENT = 'user-management',
  APP_OVERVIEW = 'app-overview',
  ANOMALY_DETECTION = 'anomaly-detection',
  ANOMALY_DETECTION_EQUIPMENT = 'anomaly-detection-equipment',
  ANOMALY_DETECTION_EQUIPMENT_SETTINGS = 'anomaly-detection-equipment-settings',
  ANOMALY_DETECTION_JOB_FEATURE_DETAILS = 'anomaly-detection-job-feature-details',
  ANOMALY_DETECTION_POSSIBLE_CAUSES = 'anomaly-detection-possible-causes',
  ASSET_MANAGEMENT = 'asset_management',
  ASSET_MANAGEMENT_MAIN = 'asset_management_main',
  ASSET_MANAGEMENT_ASSET = 'asset_management_asset',
  ASSET_MANAGEMENT_DOCUMENT = 'asset_management_document',
  ASSET_MANAGEMENT_SENSOR_MAPPING = 'asset_management_sensor_mapping',
  ASSET_MANAGEMENT_SENSOR_SETTINGS = 'asset_management_sensor_settings',
  ASSET_MANAGEMENT_DATA_SOURCES = 'asset_management_data_sources',
  ASSET_MANAGEMENT_OVERVIEW = 'asset_management_overview',
  ASSET_MANAGEMENT_LOCATION = 'asset_management_location',
  CERTIFICATION = 'certification',
  CERTIFICATION_AUDIT = 'certification_audit',
  CERTIFICATION_AUDIT_DRAFT = 'certification_audit_draft',
  CERTIFICATION_AUDIT_CONFIGURATION = 'certification_audit_configuration',
  CERTIFICATION_AUDIT_RESULT = 'certification_audit_result',
  CERTIFICATION_QUALITY = 'certification_quality',
  CERTIFICATION_QUALITY_SETTINGS = 'certification_quality_settings',
  CERTIFICATION_QUALITY_CONFIGURATION = 'certification_quality_configuration',
  CERTIFICATION_QUALITY_DRAFT = 'certification_quality_draft',
  CERTIFICATION_QUALITY_RESULT = 'certification_quality_result',
  CUSTOMER = 'customer',
  CUSTOMER_CREATE = 'customer-create',
  CUSTOMER_DETAILS = 'customer-details',
  CUSTOMER_EDIT = 'customer-edit',
  GRAFANA = 'grafana',
  HOME = 'home',
  JOB_COMPARISON = 'job-comparison',
  JOB_CHART = 'job-chart',
  LOGIN = 'login',
  NITREX_CONNECT_FURNACE = 'nitrex-connect-furnace',
  MATERIAL_LIST = 'material-list',
  MATERIAL_GROUPS = 'material-groups',
  MATERIAL_DETAILS = 'material-details',
  MATERIAL_EDIT = 'material-edit',
  MATERIAL_CREATE = 'material-create',
  MATERIAL_SETTINGS = 'material-settings',
  ML_PROTOTYPING = 'ml-prototyping',
  ROLE_MANAGEMENT = 'role-management',
  REMOTE_CONTROL = 'remote-control',
  REMOTE_CONTROL_DETAILS = 'remote-control-details',
  PART = 'parts',
  PART_SETTINGS = 'parts-settings',
  PART_DETAILS = 'parts-details',
  PART_CREATE = 'parts-create',
  PART_EDIT = 'parts-edit',
  PLAYGROUND = 'playground',
  PRIVACY_POLICY = 'privacy-policy',
  PRODUCTION_STATISTICS = 'production-statistics',
  PRODUCTION_STATISTICS_DETAILS = 'production-statistics-details',
  PRODUCTION_STATISTICS_DETAILS_JOB_RECIPE = 'production-statistics-details-job-recipe',
  PRODUCTION_LOG_LIST = 'production-log-list',
  PT9800_CONFIGURATION = 'pt9800-configuration',
  MAINTENANCE_CALENDAR = 'maintenance-calendar',
  MAINTENANCE_CALENDAR_REPORT_LIST = 'maintenance-calendar-report-list',
  MAINTENANCE_CALENDAR_JOB_LIST = 'maintenance-calendar-job-list',
  MAINTENANCE_CALENDAR_TASK_LIST = 'maintenance-calendar-task-list',
  ALARM_STATISTICS = 'alarm-statistics',
  HISTORY_AND_TRENDS = 'history-and-trends',
  LIVE_APP = 'live_app',
  LIVE_APP_LOCATION = 'live_app_location',
  LIVE_APP_EQUIPMENT = 'live_app_equipment',
  RECIPE_BUILDER = 'recipe_builder',
  RECIPE_BUILDER_VIEW = 'recipe-builder-view',
  RECIPE_BUILDER_NPM = 'recipe_builder_npm',
  RECIPE_BUILDER_NPM_DETAIL = 'recipe_builder_npm_detail',
  RECIPE_BUILDER_PT9800 = 'recipe_builder_pt9800',
  RECIPE_BUILDER_PT9800_DETAILS = 'recipe_builder_pt9800_details',
  RECIPE_BUILDER_PT9800_RECIPE_DETAILS = 'recipe_builder_pt9800_recipe_details',
  RECIPE_BUILDER_PT9800_RECIPE_CREATE = 'recipe_builder_pt9800_recipe_create',
  RECIPE_BUILDER_PT9800_RECIPE_EDIT = 'recipe_builder_pt9800_recipe_edit',
  RECIPE_ON_DEMAND = 'recipe',
  UPC_PRO = 'upc-pro',
  UPC_PRO_CARBON_OXYGEN = 'upc-pro-carbon-oxygen',
  SIMULATOR = 'simulator',
  SIMULATOR_SETTINGS = 'simulator-settings',
  SIMULATOR_SETTINGS_QUENCHING_MEDIA_DETAILS = 'simulator-settings-quenching-media_details',
  SIMULATOR_SETTINGS_QUENCHING_MEDIA_EDIT = 'simulator-settings-quenching-media_edit',
  SIMULATOR_SETTINGS_QUENCHING_MEDIA_CREATE = 'simulator-settings-quenching-media_create',
  SIMULATOR_SETTINGS_MATERIAL_CALCULATION_MODEL_DETAILS = 'simulator-settings-material-calculation-model_details',
  SIMULATOR_SETTINGS_MATERIAL_CALCULATION_MODEL_EDIT = 'simulator-settings-material-calculation-model_edit',
  SIMULATOR_SETTINGS_MATERIAL_CALCULATION_MODEL_CREATE = 'simulator-settings-material-calculation-model_create',
  SHOP_LAYOUT = 'shop-layout',
  OPERATION_CALENDAR = 'calendar',
  PERFORMANCE_DASHBOARD = 'performance-dashboard',
  PERFORMANCE_DASHBOARD_LOCATION = 'performance-dashboard-location',
  PERFORMANCE_DASHBOARD_EQUIPMENT = 'performance-dashboard-equipment',
  RELEASE_NOTES = 'release-notes',
}
