<template>
  <prm-sidebar
    :visible="visible"
    class="nav-sidebar"
    @update:visible="emit('update:visible', $event)"
  >
    <nav class="flex flex-column">
      <prm-input-text
        v-model="appSearch"
        autofocus="true"
        :placeholder="i18n.global.t('page.appOverview.search')"
      />
      <label class="prime-checkbox p-4 pl-0">
        <prm-checkbox
          v-model="showOnlyAvailableApp"
          binary
        />
        <div>
          {{ i18n.global.t('page.appOverview.showOnlyAvailableApp') }}
        </div>
      </label>
      <prm-accordion
        v-model:active-index="activeTabIndex"
        multiple
        unstyled
      >
        <prm-accordion-tab
          v-for="appGroup in appGroupList"
          :key="appGroup"
          unstyled
          :header="i18n.global.t(`api.AppGroupSemantic.${appGroup}`)"
          :pt="{
            headerAction: {
              style: {
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
              },
            },
            header: {
              style: {
                padding: '16px',
                paddingLeft: 0,
              },
            },
            content: {
              style: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                gap: '16px',
              },
            },
          }"
        >
          <template
            v-for="app in appMap[appGroup]"
            :key="app.appId"
          >
            <app-tile
              v-if="showOnlyAvailableApp
                ? !!appStore.user!.appPermissions?.[app.appId]
                : true"
              :app="app"
            />
          </template>
        </prm-accordion-tab>
      </prm-accordion>
    </nav>
  </prm-sidebar>
</template>

<script lang="ts" setup>
import PrmSidebar from 'primevue/sidebar';
import PrmCheckbox from 'primevue/checkbox';
import PrmAccordion from 'primevue/accordion';
import PrmAccordionTab from 'primevue/accordiontab';
import PrmInputText from 'primevue/inputtext';
import {
  computed,
  ref,
  shallowRef,
  watch,
} from 'vue';
import {
  i18n,
} from '@i18n';
import {
  EAppGroupSemantic,
  TAppMap,
  appGroupSemantic,
} from '@/types/app-type';
import {
  getAppMap,
} from '@/types/app-util';
import {
  useAppStore,
} from '@/store/pinia/app';
import AppTile from '@/components/app-tile/app-tile.vue';

// eslint-disable-next-line func-call-spacing, no-spaced-func
const emit = defineEmits<{
  (e: 'update:visible', value: boolean): void,
}>();

defineProps<{
  visible: boolean,
}>();

const appStore = useAppStore();
appStore.loadAppList();

const showOnlyAvailableApp = shallowRef(false);
const activeTabIndex = shallowRef<number[]>(Object.keys(appGroupSemantic).map((_, index) => index));

const appSearch = ref('');

const appMap = computed<TAppMap>(() => {
  if (!appStore.appList) {
    return {} as TAppMap;
  }
  return getAppMap(appStore.appList, appSearch.value);
});

const appGroupList = computed<EAppGroupSemantic[]>(() => {
  const result: EAppGroupSemantic[] = [];

  Object.keys(appMap.value).forEach((group) => {
    if (appMap.value[group as EAppGroupSemantic].length) {
      result.push(group as EAppGroupSemantic);
    }
  });

  return result;
});

watch(() => appStore.isNavigating, (newValue) => {
  if (newValue === false) {
    return;
  }

  emit('update:visible', false);
});
</script>

<style lang="scss">
.nav-sidebar {
  padding: env(safe-area-inset-top) 0 0 env(safe-area-inset-left);
}
</style>
