<template>
  <div
    ref="navbar"
    class="navbar"
  >
    <nav
      class="p-2 flex flex-col gap-2 items-stretch border-r h-full overflow-y-auto"
      style="border-color: var(--color-103)"
    >
      <template v-if="isAppListLoading">
        <prm-skeleton
          v-for="item in 6"
          :key="item"
          width="36px"
          height="36px"
        />
      </template>
      <template v-else>
        <prm-button
          size="small"
          text
          severity="primary"
          type="button"
          icon="pi pi-arrow-left"
          :disabled="appStore.isNavigating"
          @click="historyBack"
        />
        <div
          class="px-2"
          style="border: 1px solid var(--color-103)"
        />
        <prm-button
          v-if="appStore.isNavigating"
          v-tooltip="i18n.global.t('page.home.title')"
          disabled
          size="small"
          text
          type="button"
          severity="secondary"
          class="navbar-icon"
        >
          <template #icon>
            <home-icon class="navbar-icon__image" />
          </template>
        </prm-button>
        <router-link
          v-else
          v-slot="{ href, navigate, isActive }"
          :to="{
            name: ERouteName.HOME
          }"
          custom
        >
          <a
            :href="href"
            :title="i18n.global.t('page.home.title')"
            @click="navigate"
          >
            <prm-button
              v-tooltip="i18n.global.t('page.home.title')"
              size="small"
              text
              type="button"
              :severity="isActive
                ? 'primary'
                : 'secondary'"
              :class="{'navbar-icon': !isActive}"
            >
              <template #icon>
                <home-icon class="navbar-icon__image" />
              </template>
            </prm-button>
          </a>
        </router-link>
        <prm-button
          v-if="appStore.isNavigating"
          v-tooltip="i18n.global.t('page.appOverview.title')"
          size="small"
          text
          disabled
          type="button"
          severity="primary"
          class="navbar-icon"
        >
          <template #icon>
            <apps-icon class="navbar-icon__image" />
          </template>
        </prm-button>
        <router-link
          v-else
          v-slot="{ href, navigate, isActive }"
          :to="{
            name: ERouteName.APP_OVERVIEW,
          }"
          custom
        >
          <a
            :href="href"
            :title="i18n.global.t('page.appOverview.title')"
            @click="navigate"
          >
            <prm-button
              v-tooltip="i18n.global.t('page.appOverview.title')"
              size="small"
              text
              type="button"
              :severity="isActive
                ? 'primary'
                : 'secondary'"
              :class="{'navbar-icon': !isActive}"
            >
              <template #icon>
                <apps-icon class="navbar-icon__image" />
              </template>
            </prm-button>
          </a>
        </router-link>
        <div
          class="px-2"
          style="border: 1px solid var(--color-103)"
        />
        <prm-skeleton
          v-if="isCustomPinnedAppMapLoading"
          width="36px"
          height="36px"
        />
        <div
          v-for="(_, appId) in customUserStorage.customPinnedAppMap"
          v-else
          :key="appId"
        >
          <prm-button
            v-if="appStore.isNavigating"
            v-tooltip="i18n.global.t(`api.AppId.${appId}`)"
            size="small"
            text
            disabled
            type="button"
            severity="secondary"
            class="navbar-icon"
          >
            <template #icon>
              <component
                :is="appListMap[appId].icon"
                class="navbar-icon__image"
              />
            </template>
          </prm-button>
          <router-link
            v-else
            v-slot="{ href, navigate, isActive }"
            :to="{
              name: appListMap[appId].route,
            }"
            custom
          >
            <a
              :href="href"
              :title="i18n.global.t(`api.AppId.${appId}`)"
              @click="navigate"
            >
              <prm-button
                v-tooltip="i18n.global.t(`api.AppId.${appId}`)"
                size="small"
                text
                type="button"
                :severity="isActive
                  ? 'primary'
                  : 'secondary'"
                :class="{'navbar-icon': !isActive}"
              >
                <template #icon>
                  <component
                    :is="appListMap[appId].icon"
                    class="navbar-icon__image"
                  />
                </template>
              </prm-button>
            </a>
          </router-link>
        </div>
        <a
          v-if="!appStore.isNavigating"
          class="bottom-2"
          style="margin-top: auto; position: sticky; bottom: 0;"
          target="_blank"
          :href="config.VITE_APP_USER_MANUAL_URL"
          :title="i18n.global.t('page.userManual.title')"
        >
          <prm-button
            size="small"
            text
            type="button"
            severity="secondary"
            style="color: var(--color-224)"
          >
            <template #icon>
              <help-icon class="navbar-icon__image" />
            </template>
          </prm-button>
        </a>
      </template>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import {
  computed, onBeforeUnmount, onMounted, ref, watch,
} from 'vue';
import PrmButton from 'primevue/button';
import PrmSkeleton from 'primevue/skeleton';
import {
  i18n,
} from '@i18n';
import {
  useAppStore,
} from '@/store/pinia/app';
import {
  appListMap,
} from '@/types/app-util';
import HomeIcon from '@/assets/icon/home.svg?component';
import AppsIcon from '@/assets/icon/apps.svg?component';
import HelpIcon from '@/assets/icon/help.svg?component';
import {
  ERouteName,
} from '@/router/route-name';
import {
  useCustomUserStorageStore,
} from '@/store/pinia/custom-user-storage';
import config from '@/config/config';
import {
  rafCallback,
} from '@/utils/raf';

const appStore = useAppStore();
const customUserStorage = useCustomUserStorageStore();
customUserStorage.loadCustomPinnedAppMap();

const isAppListLoading = computed(() => appStore.appListIsLoading);
const isCustomPinnedAppMapLoading = computed(() => customUserStorage.customPinnedAppMapIsLoading);

const navbar = ref<HTMLElement | null>(null);

function historyBack() {
  window.history.back();
}

const onResize = rafCallback(() => {
  if (!navbar.value) {
    return;
  }
  document.documentElement.style.setProperty('--navbar-sidebar-width', `${navbar.value!.offsetWidth}px`);
});
onMounted(() => {
  onResize();
  window.addEventListener('resize', onResize);
  navbar.value!.addEventListener('resize', onResize);
});
onBeforeUnmount(() => {
  window.removeEventListener('resize', onResize);
  navbar.value!.removeEventListener('resize', onResize);
});

watch([
  isCustomPinnedAppMapLoading,
  isAppListLoading,
], onResize);
</script>

<style scoped lang="scss">
.navbar {
  @media print {
    width: 0px;
    overflow: none;
  }
}
.navbar-icon {
  color: var(--color-224) !important;
}
.navbar-icon__image {
    min-width: 24px;
    width: 24px;
    height: 24px;
  }
</style>
